@import url('https://rsms.me/inter/inter-ui.css');

body {
    font-family: 'Inter UI', sans-serif;
}

$button-color : linear-gradient(rgb(231, 215, 255),rgb(213, 187, 255)); //rgb(219, 199, 251);
$button-color2 : rgb(255, 180, 180);
$button-color3 : rgb(255, 72, 72);
$text-color : rgb(0, 0, 0);
$green: #2ecc71;
$red: #ff0000;
$blue: #3498db;
$blue2: #0062ff;
$yellow: #ff0000;
$purple: #8e44ad;
$turquoise: #000000;

//dunno how css works so this is mostly copy and paste

.glow-on-hover {
    font-family: 'Inter UI', sans-serif;
    width: 220px;
    height: 140px;
    border: none;
    outline: none;
    font-size : 22px;
    font-weight : 850;
    color: $text-color;
    background: $button-color;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}


.glow-on-hover:before {
    font-family: 'Inter UI', sans-serif;
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.glow-on-hover:active {
    color: $button-color;
    background : transparent;
}

.glow-on-hover:active:after {
    background: transparent;
}

.glow-on-hover:hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: $button-color;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

// Basic Button Style
.custom-btn {
    font-family: 'Inter UI', sans-serif;
    box-sizing: border-box;
    appearance: none;
    background-color: transparent;
    border: 2px solid $red;
    border-radius: 0.6em;
    color: $red;
    cursor: pointer;
    //display: flex;
    //align-self: center;
    font-size: 1rem;
    font-weight: 400;
    padding: 0.4em 1.1em;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
  
    &:hover,
    &:focus {
      color: rgb(255, 255, 255);
      outline: 0;
    }
}

//BUTTON 1
.first {
    transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
    &:hover {
      box-shadow: 0 0 40px 40px $red inset;
    }
}

.second {
    transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
    color : $blue2;
    border: 2px solid $blue2;
    &:hover {
        box-shadow: 0 0 40px 40px $blue2 inset;
    }
}

.third {
    transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;
    color : $turquoise;
    font-size: 0.85rem;
    border: 2px solid  $turquoise;
    &:hover {
        box-shadow: 0 0 40px 40px  $turquoise inset;
    }
}


// Basic Button Style
.back-btn {
    box-sizing: border-box;
    appearance: none;
    background-color: transparent;
    border: 2px solid $red;
    border-radius: 0.6em;
    color: $red;
    cursor: pointer;
    display: flex;
    align-self: center;
    font-size: 1rem;
    font-weight: 400;
    padding: 0.4em 1.1em;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    font-family: 'Inter UI', sans-serif;
    font-weight: 700;
  
    &:hover,
    &:focus {
      color: rgb(255, 255, 255);
      outline: 0;
    }
  }

.back-button {
    border-color: $blue;
    color: #fff;
    box-shadow: 0 0 40px 40px $blue inset, 0 0 0 0 $blue;
    transition: all 150ms ease-in-out;
    
    &:hover {
      box-shadow: 0 0 37px 0 $blue inset, 0 0 4px 2px $blue;
    }
  }
  
