$left-color:  #242e4d;
$right-color: #897e79;
$green-dark:  #35c3c1;
$green-light: #00d6b7;
$gray:        #8f8f8f;
$gray-light:  #f5f6f8;
@import url('https://rsms.me/inter/inter-ui.css');

  .lf--input {
    flex: 1;
    padding: 1em;
    border: 0;
    color: $gray;
    font-size: 1rem;
    font-family: 'Inter UI', sans-serif;

    &:focus {
      outline: none;
      transition: transform .15s ease;
      transform: scale(1.1);
    }
  }

  .lf--label {
    font-family: 'Inter UI', sans-serif;
    font-weight : 600;
  }

  .lf--submit {
    display: block;
    padding: 1em 1.5em;
    font-family: 'Inter UI', sans-serif;
    width: 100%;
    border-radius: 4px;

    background: linear-gradient(
      to right,
      $green-dark,
      $green-light
    );
    border: 0;
    color: #fff;
    cursor: pointer;
    font-size: .85em;
    font-weight: 700;
    text-shadow: 0 1px 0 rgba(black, .2);
    
    &:hover{
       transition: transform .15s ease;
        transform: scale(1.05);
        box-shadow: 0 0 5px 5px rgb(0, 255, 136);
    }

    &:focus {
      outline: none;
      transition: transform .15s ease;
      transform: scale(1.1);
    }
  }

::placeholder { color: $gray; }