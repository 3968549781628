
@charset "UTF-8";

.table-responsive {
    max-height : 300px;
}

.table-scroll {
    height : 300px;
    overflow-y : auto;
}